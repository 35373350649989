import { useCallback } from 'react';

import { BookDefinition } from '@/components/common/Book';
import { GridOrderedBookItemRenderer, GridOrderedBookPreset } from '@/components/genreHome/common/GridOrderedBook';
import { GridOrderedLayoutSection } from '@/components/genreHome/common/GridOrderedLayoutSection';
import { SectionLayout } from '@/components/genreHome/common/SectionLayout';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { BookViewItem, SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import type { ReadingBookItem, SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

export interface ReadingBookProps {
  section: SectionByLayout<SectionLayoutType.ReadingBook>;
}

export const ReadingBook = ({ section }: ReadingBookProps): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();

  const render = useCallback(
    ({ item, index }: { item: BookViewItem<ReadingBookItem>; index: number }) => (
      <GridOrderedBookItemRenderer key={index} item={item} index={index} />
    ),
    [],
  );

  return (
    <SectionLayout title={section.title} link={section.more_details?.path || undefined} horizontal={false}>
      <BookDefinition presets={GridOrderedBookPreset} trackingData={sectionTrackingData.bookDefinition}>
        <GridOrderedLayoutSection items={section.items} renderItem={render} />
      </BookDefinition>
    </SectionLayout>
  );
};
